import './main.sass'
// import { far } from '../../node_modules/@fortawesome/free-regular-svg-icons'
// import { fab } from '../../node_modules/@fortawesome/free-brands-svg-icons'
import { library, dom } from '../../node_modules/@fortawesome/fontawesome-svg-core'
import { faSort, faSortAlphaDownAlt, faSortAlphaUpAlt } from '../../node_modules/@fortawesome/free-solid-svg-icons'

library.add(faSort, faSortAlphaUpAlt, faSortAlphaDownAlt) // , far, fab)

dom.watch()

document.addEventListener('DOMContentLoaded', (event) => {
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)

  if ($navbarBurgers.length > 0) {
    $navbarBurgers.forEach(el => {
      el.addEventListener('click', () => {
        const target = el.dataset.target
        const $target = document.getElementById(target)

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active')
        $target.classList.toggle('is-active')
      })
    })
  }
})
